import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { StaticImage } from "gatsby-plugin-image"
import iconX from '../images/x.svg'
import iconXOrange from '../images/x-orange.svg'

const Index = ({ data, location }) => {
  const intl = useIntl();
  const { title } = data.site.siteMetadata
  return (
    <Layout location={location} siteTitle={title}>
      <Seo title={intl.formatMessage({id: "navlinks.about"})} lang={intl.locale} />
      <section>
        <div className="container-hero">
          <div className="text-hero">
            <h1 className="heading-hero">
              <FormattedMessage id="index.headingHero" />
            </h1>
          </div>
          <StaticImage
            className="cover-slider"
            src="../images/antispecista.jpg"
            alt=""
            placeholder="blurred"
          />
        </div>
      </section>
      <section className="who">
        <div className="container container-home">
          <div className="container-who-what-why">
            <div className="caption-with-big-title">
              <h3>
                <FormattedMessage id="index.captionWho" />
              </h3>
              <div>
                <FormattedMessage id="index.bigTitleWho" />
              </div>
            </div>
            <p>
              <FormattedMessage id="index.paragraphWho1" />
            </p>
            <p>
              <FormattedMessage id="index.paragraphWho2" />
            </p>
            <p>
              <FormattedMessage id="index.paragraphWho3" />
            </p>
          </div>
        </div>
        <div className="rotating-stuff right-bottom-corner">
          <img className="rotating" src={iconX} alt="" />
        </div>
      </section>
      <section className="what">
        <div className="container container-home">
          <div className="container-who-what-why">
            <div className="caption-with-big-title">
              <h3>
                <FormattedMessage id="index.captionWhat" />
              </h3>
              <div>
                <FormattedMessage id="index.bigTitleWhat" />
              </div>
            </div>
            <p>
              <FormattedMessage id="index.paragraphWhat1" />
            </p>
            <p>
              <FormattedMessage id="index.paragraphWhat2" />
            </p>
          </div>
        </div>
        <div className="rotating-stuff right-top-corner">
          <img className="rotating" src={iconXOrange} alt="" />
        </div>
      </section>
      <section className="why">
        <div className="container container-home">
          <div className="container-who-what-why">
            <div className="caption-with-big-title">
              <h3>
                <FormattedMessage id="index.captionWhy" />
              </h3>
              <div>
                <FormattedMessage id="index.bigTitleWhy" />
              </div>
            </div>
            <p>
              <FormattedMessage id="index.paragraphWhy1" />
            </p>
            <p>
              <FormattedMessage id="index.paragraphWhy2" />
            </p>
            <p>
              <FormattedMessage id="index.paragraphWhy3" />
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

  }
`
